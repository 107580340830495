(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$window', '$rootScope', '$scope', '$translate', '$localStorage', '$sce', 'toastr', 'Principal',
        'StoreService', 'HubService', 'HomeService','SatisfactionSurveyService', '$timeout', 'Account',
    ];

    function HomeController($window, $rootScope, $scope, $translate, $localStorage, $sce, toastr, Principal,
        StoreService, HubService, HomeService, SatisfactionSurveyService, $timeout, Account) {
        var vm = this;
        vm.store = $localStorage.defaultStore;
        vm.loading = true;

		init();

		function init() {
			getAccount();
		}

		function getAccount() {
            Principal.identity(false).then(function (account) {
                vm.account = account;
				vm.userProfile = vm.account.userProfiles[0];

				getData();

				$timeout(function(){
					if ($window.OneSignal) {
						$window.OneSignal.push(function() {
							OneSignal.Notifications.addEventListener("permissionChange", eventListener);
							$window.OneSignal.Notifications.requestPermission();
						});
					}
				}, 4000);

                if (vm.account.isHubAdmin) {
                    HubService.getHubByOwner(vm.account.id).then(function (result) {
                        vm.hub = result;
                        $localStorage.hub = vm.hub;
                    });
                }else{
	 				HubService.getHubByStoreId(account.userProfiles[0].storeId).then(function (result) {
	                    vm.hub = result;
	                    $localStorage.hub = vm.hub;
	                });
				}
                if (account.userProfiles) {
                    if ($rootScope.ADMIN_SYSTEM_ID != account.userProfiles[0].storeId) {
                        getPendencies(account.userProfiles[0].storeId)
                    }
                }
                $translate.use(vm.account.langKey);

				if (vm.account.acceptedTerm) {
					showModalSatisfaction();
				}
            });
        }

		function getData() {
			if (vm.userProfile.profileId == 7) {
				vm.videos = [
		            {
		                title: $translate.instant('home.ambassador.steps.step1.title'),
		                subtitle: $translate.instant('home.ambassador.steps.step1.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/showkase/home-step-customer.png',
		                url: 'https://www.youtube.com/embed/G0KwigqWoTMaaa'
		            },
		            {
		                title: $translate.instant('home.ambassador.steps.step2.title'),
		                subtitle: $translate.instant('home.ambassador.steps.step2.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/showkase/home-step-flyer.png',
		                url: 'https://www.youtube.com/embed/QYANOySV4qMaaa'
		            },
		            {
		                title: $translate.instant('home.ambassador.steps.step3.title'),
		                subtitle: $translate.instant('home.ambassador.steps.step3.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/showkase/icone-links.png',
		                url: 'https://www.youtube.com/embed/ROJbQgf79Wwaaa'
		            },
					{
		                title: $translate.instant('home.ambassador.steps.step4.title'),
		                subtitle: $translate.instant('home.ambassador.steps.step4.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/showkase/home-step-payment.png',
		                url: 'https://www.youtube.com/embed/ROJbQgf79Wwaaa'
		            }
		        ];
			} else if (vm.store.hubId != 4) {
				vm.videos = [
		            {
		                title: $translate.instant('home.steps.step1.title'),
		                subtitle: $translate.instant('home.steps.step1.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/home-step-setting.png',
		                url: 'https://www.youtube.com/embed/G0KwigqWoTM',
		                actionText: $translate.instant('home.steps.step1.box-subtitle'),
		                actions: [
		                    {
		                        label: $translate.instant('home.steps.step1.button1'),
		                        url: 'store-detail({fromStep: true})'
		                    }
		                ]
		            },
		            {
		                title: $translate.instant('home.steps.step2.title'),
		                subtitle: $translate.instant('home.steps.step2.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/home-step-flyer.png',
		                url: 'https://www.youtube.com/embed/QYANOySV4qM',
		                actionText: $translate.instant('home.steps.step2.box-subtitle'),
		                actions: [
		                    {
		                        label: $translate.instant('home.steps.step2.button1'),
		                        url: 'flyers.new'
		                    }
		                ]
		            },
		            {
		                title: $translate.instant('home.steps.step3.title'),
		                subtitle: $translate.instant('home.steps.step3.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/home-step-showcase.png',
		                url: 'https://www.youtube.com/embed/ROJbQgf79Ww',
		                actionText: $translate.instant('home.steps.step3.box-subtitle'),
		                actions: [
		                    {
		                        label: $translate.instant('home.steps.step3.button1'),
		                        url: 'showcases.new'
		                    }
		                ]
		            },
		            {
		                title: $translate.instant('home.steps.step4.title'),
		                subtitle: $translate.instant('home.steps.step4.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/icone-links.png',
		                links: true,
		                titleRight: $translate.instant('home.steps.step4.box-title'),
		                subtitleRight: $translate.instant('home.steps.step4.box-subtitle'),
		                actionLabel: $translate.instant('home.steps.step4.button2'),
						link: $translate.instant('home.steps.step4.button1')
		            },
		            {
		                title: $translate.instant('home.steps.step5.title'),
		                subtitle: $translate.instant('home.steps.step5.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/home-step-payment.png',
		                url: 'https://www.youtube.com/embed/pIb1nvLs0vM',
		                actionText: $translate.instant('home.steps.step5.box-subtitle'),
		                actions: [
		                    {
		                        label: $translate.instant('home.steps.step5.button1'),
		                        url: 'payment-settings'
		                    },
		                    {
		                        label: $translate.instant('home.steps.step5.button2'),
		                        url: 'shipping-settings'
		                    }
		                ]
		            },
		            {
		                title: $translate.instant('home.steps.step6.title'),
		                subtitle: $translate.instant('home.steps.step6.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/home-step-customer.png',
		                url: 'https://www.youtube.com/embed/rvDMA_P5jQkaa',
		                actionText: $translate.instant('home.steps.step6.box-subtitle'),
		                actions: [
		                    {
		                        label: $translate.instant('home.steps.step6.button1'),
		                        url: 'customers'
		                    },
		                    {
		                        label: $translate.instant('home.steps.step6.button2'),
		                        url: 'invoices'
		                    }
		                ]
		            },
		            {
		                title: $translate.instant('home.steps.step7.title'),
		                subtitle: $translate.instant('home.steps.step7.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/home-step-social-network.png',
		                url: 'https://www.youtube.com/embed/y6MLML5REPU',
		                actionText: $translate.instant('home.steps.step7.box-subtitle'),
		                actions: [
		                    {
		                        label: $translate.instant('home.steps.step7.button1'),
		                        url: 'social-integrations'
		                    }
		                ]
		            }
		        ];
			} else {
			    vm.videos =  [
					{
		                title: $translate.instant('home.steps.step1.title'),
		                subtitle: $translate.instant('home.steps.step1.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/home-step-setting.png',
		                url: 'https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/dupay/step_01_dupay.png',
		                actionText: $translate.instant('home.steps.step1.box-subtitle'),
		                actions: [
		                    {
		                        label: $translate.instant('home.steps.step1.button1'),
		                        url: 'store-detail({fromStep: true})'
		                    }
		                ]
		            },
					{
		                title: $translate.instant('home.steps.step2.title'),
		                subtitle: $translate.instant('home.steps.step2.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/home-step-flyer.png',
		                url: 'https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/dupay/step_02_dupay.png',
		                actionText: $translate.instant('home.steps.step2.box-subtitle'),
		                actions: [
		                    {
		                        label: $translate.instant('home.steps.step2.button1'),
		                        url: 'flyers.new'
		                    }
		                ]
		            },
					{
		                title: $translate.instant('home.steps.step3.title'),
		                subtitle: $translate.instant('home.steps.step3.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/home-step-showcase.png',
		                url: 'https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/dupay/step_03_dupay.png',
		                actionText: $translate.instant('home.steps.step3.box-subtitle'),
		                actions: [
		                    {
		                        label: $translate.instant('home.steps.step3.button1'),
		                        url: 'showcases.new'
		                    }
		                ]
		            },
		            {
		                title: $translate.instant('home.steps.step4.title'),
		                subtitle: $translate.instant('home.steps.step4.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/icone-links.png',
		                links: true,
		                titleRight: $translate.instant('home.steps.step4.box-title'),
		                subtitleRight: $translate.instant('home.steps.step4.box-subtitle'),
		                actionLabel: $translate.instant('home.steps.step4.button2'),
						link: $translate.instant('home.steps.step4.button1')
		            },
		            {
		                title: $translate.instant('home.steps.step5.title'),
		                subtitle: $translate.instant('home.steps.step5.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/home-step-payment.png',
		                url: 'https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/dupay/step_05_dupay.png',
		                actionText: $translate.instant('home.steps.step5.box-subtitle'),
		                actions: [
		                    {
		                        label: $translate.instant('home.steps.step5.button1'),
		                        url: 'payment-settings'
		                    },
		                    {
		                        label: $translate.instant('home.steps.step5.button2'),
		                        url: 'shipping-settings'
		                    }
		                ]
		            },
					{
		                title: $translate.instant('home.steps.step6.title'),
		                subtitle: $translate.instant('home.steps.step6.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/home-step-customer.png',
		                url: 'https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/dupay/step_06_dupay.png',
		                actionText: $translate.instant('home.steps.step6.box-subtitle'),
		                actions: [
		                    {
		                        label: $translate.instant('home.steps.step6.button1'),
		                        url: 'customers'
		                    },
		                    {
		                        label: $translate.instant('home.steps.step6.button2'),
		                        url: 'invoices'
		                    }
		                ]
		            },
					{
		                title: $translate.instant('home.steps.step7.title'),
		                subtitle: $translate.instant('home.steps.step7.subtitle'),
		                imagePath: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/home-step-social-network.png',
		                url: 'https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/dupay/step_07_dupay.png',
		                actionText: $translate.instant('home.steps.step7.box-subtitle'),
		                actions: [
		                    {
		                        label: $translate.instant('home.steps.step7.button1'),
		                        url: 'social-integrations'
		                    }
		                ]
		            }
		        ];
			}
		}

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

		function eventListener(event) {
			if (event) {
				if($window.OneSignal && $window.OneSignal.User && $window.OneSignal.User._currentUser ) {
					Principal.savePushNotificationId($window.OneSignal.User._currentUser.onesignalId).then(function (response) {
						// TODO: Some code
					});
				}
			}
		}

        function showModalSatisfaction() {
            SatisfactionSurveyService.checkIfStoreNew(vm.account.userProfiles[0].storeId).then(function (response) {
                if (!response.data) {
                    SatisfactionSurveyService.checkIfNotExpiredEvaluation(vm.account.userProfiles[0].storeId, vm.account.id).then(function (response) {
                        if (!response.data) {
                            SatisfactionSurveyService.rateStore().then(function () {});
                        }
                    });
                }
            });
        }


        function getPendencies(storeId) {
            HomeService.getStoreInfo(storeId).then(function (response) {
                vm.storePendencies = response.data;
                if (vm.storePendencies.hasPublishedShowcase) {
                    getReport(vm.account.userProfiles[0].storeId)
                } else {
                    vm.loading = false;
                }
                selectCurrentVideo();
            }).catch(function () {
                vm.loading = false;
            })
        }

        function getReport(storeId) {
            vm.loading = true;
            HomeService.getReport(storeId).then(function (response) {
                vm.report = response.data;
                vm.loading = false;
            }).catch(function () {
                vm.loading = false;
            })
        }

        function selectCurrentVideo() {
			getData();
			if (vm.userProfile.profileId == 7) {
				vm.video = vm.videos[0];
				vm.videoStep = 0;
			} else {
				if (!vm.storePendencies.filledStoreInfo) {
					vm.video = vm.videos[0];
					vm.videoStep = 0;
				} else if (!vm.storePendencies.hasFlyer) {
					vm.video = vm.videos[1];
					vm.videoStep = 1;
				} else if (!vm.storePendencies.hasPublishedShowcase) {
					vm.video = vm.videos[2];
					vm.videoStep = 2;
				} else if (!vm.storePendencies.hasPaymentGateway || !vm.storePendencies.hasShippingGateway) {
					vm.video = vm.videos[4];
					vm.videoStep = 4;
				} else {
					vm.video = vm.videos[5];
					vm.videoStep = 5;
				}
			}
        }

        vm.changeStep = function (step) {
			getData();
            vm.videoStep += step;
            vm.video = vm.videos[vm.videoStep];
        }

        vm.getTrustedUrl = function (url) {
            return $sce.trustAsResourceUrl(url);
        }

        vm.getShowcaseURL = function() {
            if (!$localStorage.defaultStore.storeNickname) {
                getStoreInfo().then(function(response) {
                    $localStorage.defaultStore.storeNickname = response.data.nickname;
                    $localStorage.defaultStore.storeType = response.data.storeType;
                    vm.store = response.data;
                    return ('https://' + $localStorage.defaultStore.storeNickname.toLowerCase() + '.' + getDomainNameSufix($window, vm.store.storeType) + '/links');
                });
            } else {
               return ('https://' + $localStorage.defaultStore.storeNickname.toLowerCase() + '.' + getDomainNameSufix($window, vm.store.storeType) + '/links');
            }

        }

        function getStoreInfo() {
            return StoreService.getStore($localStorage.defaultStore.storeId);
        }

        getStoreInfo().then(function(response) {
            $localStorage.defaultStore.storeType = response.data.storeType;
            vm.store = response.data;
        });

		vm.showTotalSales = function () {
			var jcMotosIds = [2078, 2079];
			var profileIds = [2, 4, 9];

			if (!$localStorage.defaultStore || !$localStorage.defaultStore.storeId) {
				return false;
			}

			if (jcMotosIds.includes($localStorage.defaultStore.storeId)) {
				return profileIds.includes($localStorage.defaultStore.profileId);
			} else {
				return true;
			}
		}

        vm.copyLink =  function () {
            var text = vm.getShowcaseURL();
            var msgSuccess = "Link copiado com sucesso";

            if (window.clipboardData && window.clipboardData.setData) {
                toastr.success(msgSuccess);
                return clipboardData.setData("Text", text);

            } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                var textarea = document.createElement("textarea");
                textarea.textContent = text;
                textarea.style.position = "fixed";
                document.body.appendChild(textarea);
                textarea.select();
                try {
                    toastr.success(msgSuccess);
                    return document.execCommand("copy");
                } catch (ex) {
                    return false;
                } finally {
                    document.body.removeChild(textarea);
                }
            }
        }

    }
})();
